<template>
  <div  class="w-m-content">
    <div class="w-m-header">
      <p class="font-14 no-margin no-select" style="color: #293e52">Lançar pagamentos de Produtividade no Financeiro</p>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1">
      <div class="font-11">
        <e-row mr>
          <e-col>
            <erp-s-field label="Data inicial">
              <erp-input v-model="filtros.data1" placeholder="dd/mm/aaaa" v-mask="'##/##/####'" autofocus />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Data final">
              <erp-input v-model="filtros.data2" placeholder="dd/mm/aaaa" v-mask="'##/##/####'" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <u-btn @click="$uloc.window.close($root.wid)" label="Cancelar" no-caps
             :color="'white'"
             text-color="grey-8"
             class="b-radius-3px btn-white m-r-sm"/>
      <u-btn :loading="loading" @click="lancar" label="Registrar pagamento" no-caps
             :color="'primary'"
             class="b-radius-3px"/>
    </div>
  </div>
</template>

<script>
import {ErpInput, ErpSField} from 'uloc-vue-plugin-erp'
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
// import {UCheckbox} from "uloc-vue"
// import {registraPagamentoEtapa} from "@/domain/processos/services/produtividade"
import {VMoney} from "v-money"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {processarProdutividadeFinanceiro} from "@/domain/processos/services/produtividade"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import {donwloadFile} from "@/utils/downloadFile";

export default {
  name: 'LancarFinanceiroProdutividadeWindow',
  directives: {money: VMoney},
  mixins: [PersonMixin],
  components: {
    ERow,
    ErpInput,
    ErpSField,
    ECol
  },
  props: ['options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      loading: false,
      success: false,
      filtros: {
        data1: null,
        data2: null
      }
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    lancar () {
      if (this.loading) return
      this.loading = true
      processarProdutividadeFinanceiro(this.filtros)
          .then(response => {
            donwloadFile(response)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
